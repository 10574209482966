import media from '../../media/Friendsgiving.png'
import './News.css'

function News() {
    return (
      <div>
        <img className='news' src={media} alt="News" />
      </div>
    );
  }
  
  export default News;